//*// BEHAVIORS //*//
.newsImageContainer {
  margin: 0 0 45px;
  a {
    .clearfix();
    display: block;
    &:hover {
      text-decoration: none;
      figcaption {
        text-decoration: underline;
      }
    }
  }
  a img {
    display: block;
    height: auto;
    margin-bottom: 5px;
    max-width: 100%;
  }
  figcaption {
    clear: both;
    color: @plone-gray-dark;
    font-family: @plone-font-family-serif;
    font-size: 1.250rem;
    font-weight: 300;
    line-height: 1.750rem;
    width: 200px;
  }
}
