//*// SITE NAV //*//
// .plone-nav {
//   font-family: @plone-font-family-serif;
//   list-style: none;
//   margin-bottom: 0;
//   padding-left: 0;
//   @media (max-width: @plone-screen-xs-max) {
//     padding: 5px 0 15px;
//   }
//   > li {
//     display: block;
//     @media (min-width: @plone-screen-sm-min) {
//       display: inline-block;
//       margin-left: 50px;
//     }
//     @media (min-width: @plone-grid-large-breakpoint) {
//       margin-left: 56px;
//     }
//     &.selected > a {
//       &,
//       &:hover,
//       &:focus {
//         background-color: fade(#fff, 15%);
//         @media (min-width: @plone-screen-sm-min) {
//           text-decoration: underline;
//         }
//       }
//     }
//     > a {
//       color: @plone-text-color;
//       display: block;
//       @media (max-width: @plone-screen-xs-max) {
//         color: #fff;
//         padding: 5px 20px;
//       }
//       &:hover,
//       &:focus {
//         outline: none;
//         text-decoration: none;
//         @media (max-width: @plone-screen-xs-max) {
//           background-color: fade(#fff, 15%);
//         }
//         @media (min-width: @plone-screen-sm-min) {
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }
// .plone-navbar-collapse {
//   &:extend(.clearfix all);
//   -webkit-overflow-scrolling: touch;
//   display: none;
//   overflow-x: visible;
//   &.in {
//     display: block;
//     overflow-y: auto;
//     @media (max-width: @plone-screen-xs-max) {
//       background-color: @plone-primary-color;
//       left: 0;
//       position: absolute;
//       right: 0;
//       z-index: 1;
//     }
//   }
//   @media (min-width: @plone-grid-float-breakpoint) {
//     border-top: 0;
//     box-shadow: none;
//     width: auto;
//     &.plone-collapse {
//       display: block !important;
//       height: auto !important;
//       overflow: visible !important;
//       padding-bottom: 0;
//     }
//     &.in {
//       overflow-y: visible;
//     }
//     .navbar-fixed-top &,
//     .navbar-static-top &,
//     .navbar-fixed-bottom & {
//       padding-left: 0;
//       padding-right: 0;
//     }
//   }
// }

// .plone-navbar-toggle {
//   background-color: @plone-primary-color;
//   border-radius: 4px;
//   border: 1px solid @plone-primary-color;
//   box-shadow: none;
//   padding: 10px;
//   position: relative;
//   z-index: 1;
//   @media (min-width: @plone-grid-float-breakpoint) {
//     display: none;
//   }
//   &:hover,
//   &:focus,
//   &:active {
//     background-color: @plone-primary-color;
//     border-color: @plone-primary-color;
//     box-shadow: none;
//     color: #fff;
//     outline: 0;
//   }
//   &[aria-expanded='true'] {
//     border-radius: 4px 4px 0 0;
//   }
//   .icon-bar {
//     background: white;
//     border-radius: 1px;
//     display: block;
//     height: 2px;
//     margin-top: 2px;
//     width: 22px;
//   }
//   .icon-bar + .icon-bar {
//     margin-top: 5px;
//   }
//   .icon-bar + .icon-bar + .icon-bar {
//     margin-bottom: 3px;
//   }
// }
// #mainnavigation-wrapper {
//   .clearfix();
//   .section-front-page & {
//     background-color: @plone-gray-lighter;
//   }
// }
// #portal-globalnav {
//   @media (min-width: @plone-screen-sm-min) {
//     font-size: 1.875rem;
//     letter-spacing: 2px;
//     margin-bottom: 32px;
//     text-align: right;
//   }
//   .glyphicon {
//     display: none;
//   }
// }





.navbar-barceloneta {
  --bs-navbar-color: white;
  --bs-navbar-active-color: white;
  --bs-navbar-hover-color: white;
  --bs-navbar-background: #007bb1;
  --bs-navbar-active-background: rgba(0, 0, 0, 0.2);
  --bs-navbar-hover-background: rgba(0, 0, 0, 0.3);
  // .section-front-page & {
  //   background-color: @plone-gray-lighter;
  // }
}


#portal-globalnav {
  font-size: 1.875rem;
  letter-spacing: 2px;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Din";
  a {
    color: #444;
    display: block;
  }
}

@media (min-width: 768px) {
  #portal-globalnav {
    margin-bottom: 32px;
    text-align: right;
    li {
      display: inline-block;
      margin-left: 50px;
    }
    .inPath,
    .current {
      a {
        text-decoration: underline;
      }
    }
  }
}

[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}
.border-secondary {
  --bs-border-opacity: 1;
  border-color: @plone-primary-color;
}
.navbar-toggler {
  padding: 8px 10px;
  line-height: 1;
  color: #fff;
  background-color: @plone-primary-color;
  border: 1px solid @plone-primary-color;
  border-radius: 0.375rem;
  transition: box-shadow 0.15s ease-in-out;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 22px;
    height: 2px;
    background-color: #fff;
    margin: 5px 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
}

button, select {
  text-transform: none;
}

.navbar-toggler-icon {
  display: block;
  width: 22px;
  height: 2px;
  background-color: #fff;
  // display: inline-block;
  // width: 1.5em;
  // height: 1.5em;
  // vertical-align: middle;
  // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 100%;
}


.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  opacity: .5;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
  margin: 0 0 30px auto;
}
.justify-content-end {
  justify-content: flex-end;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas.hiding, .offcanvas.show, .offcanvas.showing {
  visibility: visible;
}
.offcanvas {
  color: var(--bs-offcanvas-color);
  visibility: hidden;
}
.offcanvas.show:not(.hiding), .offcanvas.showing {
  transform: none;
}
.navbar .offcanvas {
  --bs-offcanvas-bg: var(--bs-navbar-background);
}
.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
  visibility: visible;
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: #fff;
  visibility: hidden;
  background-color: @plone-primary-color;
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out;
  padding: 15px 30px;
  @media (min-width: @plone-screen-sm-min) {
    padding: 0;
  }
}
.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}