//*// HEADER //*//
#above-content-wrapper {
    .clearfix();
    // .section-front-page & {
    //     background-color: @plone-gray-lighter;
    // }
}
#content-header {
    .clearfix();
    @media (max-width: @plone-screen-xs-max) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: center;
        padding: 15px;
    }
    // .section-front-page & {
    //     background-color: @plone-gray-lighter;
    // }
}
#portal-header {
    .clearfix();
    @media (min-width: @plone-screen-sm-min) {
        margin: 66px auto 20px;
    }
    .section-front-page & {
        background-color: @plone-gray-lighter;
    }
}
#portal-logo {
    float: left;
    @media (min-width: @plone-screen-sm-min) {
        margin: 22px 0 0;
    }
    img {
      @media (max-width: @plone-screen-xs-max) {
        height: auto;
        max-width: 100%;
        position: relative;
        z-index: 2;
      }
    }
}

#portal-top {
    .clearfix();
    @media (min-width: 768px) {
      margin: 66px auto 20px;
    }
}

#portal-languageselector {
    clear: both;
    float: left;
    margin-right: 29px;
    padding-top: 5px;
    li {
        display: inline-block;
        margin-right: 10px;
    }
}
#portal-anontools {
    @media (max-width: @plone-screen-xs-max) {
        display: none;
    }
    float: right;
    ul {
        padding-right: 0;
    }
}
#portal-membertools-wrapper {
	float: right;
    padding: @plone-padding-base-horizontal 0;
	ul {
        margin: 0 0 @plone-padding-base-horizontal @plone-padding-base-horizontal;
		padding-right: 0;
	}
}

.d-none {
    display: none !important;
}
.text-decoration-none {
    text-decoration: none !important;
}

//mobile search
#portal-searchbox {
    @media (max-width: @plone-screen-xs-max) {
        display: none;
    }
    clear: left;
    margin-left: 3px;
    margin-top: 60px;
    padding-top: 20px;
    position: absolute;
    // white-space: nowrap;
    z-index: 2;
    .searchSection {display: none;} //non on mobile
    [type="submit"] {display: none;} //submit hidden on mobile
    label {font-size: 86.667%}
}

//non mobile search
@media (min-width: @plone-grid-float-breakpoint) {
    #portal-header {
        margin-bottom: 15px;
    }
	#portal-languageselector {
        clear: none;
        float: right;
	}
    #portal-searchbox {
        clear: none;
        float: right;
        margin-left: 1em;
        margin-top: 5px; //visual balance
        max-width: 50%;
        padding-top: 0;
        position: relative;
        .searchSection {
            display: inherit;
            label {
                vertical-align: middle;
                font-size: 14px;
            }
        } //yes for non mobile
        .livesearch-results {
            border-color: @plone-primary-color;
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px @plone-primary-color;
            font-size: 16px;
            line-height: 20px;
            position: absolute;
            background: #fff;
            left: 0;
            right: 0;
            margin: 0;
            padding: 10px 0;
            list-style: none;
            li {
                &.no-results,
                &.results-summary {
                    padding: 10px 15px;
                }
                &.load-more {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 30px;
                }
            }
            a {
                display: block;
                padding: 20px 30px 30px;

                &:hover {
                    background-color: #eee;
                }
            }
            .heading {
                font-size: 20px;
                margin-bottom: 5px;
            }
            .description {
                color: #000;
                margin-bottom: 5px;
            }
        }
        [type="text"] {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            float: left;
            width: 248px;
            height: auto;
            padding: 3px 15px;
        }
        [type="submit"] {
            display: inline-block;
            margin-left: -1px;
            vertical-align: top;
            font-size: 18px;
            padding: 3px 15px;
            width: auto;
            display: inline-block;
        }
    }
}
