@charset "UTF-8";

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #007bb1;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 0, 123, 177;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #007bb1;
  --bs-link-hover-color: #00628e;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --plone-link-color: #007bb1;
  --plone-link-color-on-dark: #16a1e3;
  --plone-link-color-on-grey: #086ca3;
  --plone-link-hover-color: #004665;
  --plone-state-draft: #fab82a;
  --plone-state-pending: #e2e721;
  --plone-state-private: #c4183c;
  --plone-state-internal: #fab82a;
  --plone-state-internally-published: #883dfa;
    --plone-toolbar-bg: var(--bs-dark);
    --plone-toolbar-text-color: var(--bs-white);
    --plone-toolbar-locked-color: var(--bs-warning);
    --plone-toolbar-font: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --plone-toolbar-separator-color: var(--bs-gray-500);
    --plone-toolbar-link-color: var(--plone-link-color);
    --plone-toolbar-link-hover-color: var(--plone-link-hover-color);
    --plone-toolbar-width: 220px;
    --plone-toolbar-width-collapsed: 60px;
    --plone-toolbar-top-height: 2.5rem;
}

#edit-bar {
  .dropup,
  .dropend,
  .dropdown,
  .dropstart,
  .dropup-center,
  .dropdown-center {
    position: relative;
  }

  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropdown-menu {
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 1rem;
    --bs-dropdown-color: #212529;
    --bs-dropdown-bg: #fff;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: 0.375rem;
    --bs-dropdown-border-width: 1px;
    --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-dropdown-link-color: #212529;
    --bs-dropdown-link-hover-color: #1e2125;
    --bs-dropdown-link-hover-bg: #e9ecef;
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #007bb1;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
  }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer);
  }

  .dropdown-menu-start {
    --bs-position: start;
  }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-end {
    --bs-position: end;
  }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto;
  }

  @media (min-width: 576px) {
    .dropdown-menu-sm-start {
      --bs-position: start;
    }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-end {
      --bs-position: end;
    }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start;
    }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-end {
      --bs-position: end;
    }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start;
    }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-end {
      --bs-position: end;
    }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start;
    }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-end {
      --bs-position: end;
    }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
      --bs-position: start;
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xxl-end {
      --bs-position: end;
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  .dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--bs-dropdown-spacer);
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer);
  }
  .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropend .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropend .dropdown-toggle::after {
    vertical-align: 0;
  }

  .dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--bs-dropdown-spacer);
  }
  .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  .dropstart .dropdown-toggle::after {
    display: none;
  }
  .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  .dropstart .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropstart .dropdown-toggle::before {
    vertical-align: 0;
  }

  .dropdown-divider {
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    opacity: 1;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
  }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg);
  }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    text-decoration: none;
    background-color: transparent;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-header {
    display: block;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: 0.875rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap;
  }

  .dropdown-item-text {
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-dropdown-link-color);
  }

  .dropdown-menu-dark {
    --bs-dropdown-color: #dee2e6;
    --bs-dropdown-bg: #343a40;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-link-color: #dee2e6;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #007bb1;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-header-color: #adb5bd;
  }

  .nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none;
    }
  }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color);
  }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: #dee2e6;
    --bs-nav-tabs-border-radius: 0.375rem;
    --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
    --bs-nav-tabs-link-active-color: #495057;
    --bs-nav-tabs-link-active-bg: #fff;
    --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    isolation: isolate;
    border-color: var(--bs-nav-tabs-link-hover-border-color);
  }
  .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
  }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .nav-pills {
    --bs-nav-pills-border-radius: 0.375rem;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #007bb1;
  }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius);
  }
  .nav-pills .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg);
  }

  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }

  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }

  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link {
    width: 100%;
  }

  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }


  .border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }


  .flex-fill {
    flex: 1 1 auto !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}

/* general collapsed dark mode */
#edit-zone {
  font-family: var(--plone-toolbar-font);
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--plone-toolbar-bg);
  width: var(--plone-toolbar-width);
  color: var(--plone-toolbar-text-color);
  border-right: none;
  font-size: 1rem;
}
#edit-zone,
#edit-zone .dropdown-menu {
  background-color: var(--plone-toolbar-bg);
}
#edit-zone .dropdown-header {
  color: var(--plone-toolbar-text-color);
}
#edit-zone .dropdown-menu {
  border-radius: 0;
  min-width: 12rem;
  overflow-y: scroll;
  max-height: 100vh;
}
#edit-zone .nav-link,
#edit-zone .dropdown-item {
  color: var(--plone-toolbar-text-color);
}
#edit-zone .nav-link:hover, #edit-zone .nav-link:focus,
#edit-zone .dropdown-item:hover,
#edit-zone .dropdown-item:focus {
  background: var(--plone-toolbar-link-color);
  color: var(--plone-toolbar-text-color);
}
#edit-zone .nav-link:hover .label-state-published,
#edit-zone .nav-link:hover .label-state-external, #edit-zone .nav-link:focus .label-state-published,
#edit-zone .nav-link:focus .label-state-external,
#edit-zone .dropdown-item:hover .label-state-published,
#edit-zone .dropdown-item:hover .label-state-external,
#edit-zone .dropdown-item:focus .label-state-published,
#edit-zone .dropdown-item:focus .label-state-external {
  color: var(--plone-toolbar-link-hover-color) !important;
}
#edit-zone .toolbar-header {
  background: var(--bs-primary);
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  height: var(--plone-toolbar-top-height);
}
#edit-zone .toolbar-header a {
  color: var(--plone-toolbar-text-color);
  cursor: pointer;
}
@media (max-width: 767.98px) {
  #edit-zone .toolbar-header a {
    display: none;
  }
}
#edit-zone .label-state-published,
#edit-zone .label-state-external {
  color: var(--plone-link-color-on-dark) !important;
}
#edit-zone .label-state-internally_published {
  color: var(--plone-state-internally-published-color) !important;
}
#edit-zone .label-state-pending {
  color: var(--plone-state-pending) !important;
}
#edit-zone li.active .label-state-pending,
#edit-zone .label-state-pending:hover {
  color: var(--plone-toolbar-text-color) !important;
}
#edit-zone .label-state-draft,
#edit-zone .label-state-internal {
  color: var(--plone-state-draft) !important;
}
#edit-zone .label-state-private {
  color: var(--plone-state-private) !important;
}
#edit-zone .personaltools-wrapper {
  margin-top: auto;
  border-top: solid 1px var(--plone-toolbar-separator-color);
  padding: 0.5rem 0;
}

body.plone-toolbar-left {
  padding-left: var(--plone-toolbar-width-collapsed);
}
body.plone-toolbar-left .toolbar-expand,
body.plone-toolbar-left .toolbar-collapse {
  display: none;
}
body.plone-toolbar-left #edit-zone .nav-link {
  display: flex;
  width: 100%;
  padding-right: 0.5rem;
  align-items: center;
}
body.plone-toolbar-left #edit-zone .nav-link:active,
body.plone-toolbar-left #edit-zone .nav-link:focus,
body.plone-toolbar-left #edit-zone .nav-link:hover {
  text-decoration: none;
}
body.plone-toolbar-left #edit-zone .nav-link .plone-icon {
  margin-right: 0.5em;
  flex-shrink: 0;
}
body.plone-toolbar-left #edit-zone .nav-link .toolbar-label {
  flex-grow: 1;
}
body.plone-toolbar-left #edit-zone .nav-link:after {
  margin-top: 0.5em;
  width: 0.3em;
  height: 0.3em;
}
body.plone-toolbar-left #edit-zone .border-top {
  border-top-color: var(--plone-toolbar-separator-color);
}
body.plone-toolbar-left .toolbar-expand {
  display: block;
}
body.plone-toolbar-left #edit-zone {
  visibility: visible !important;
  transform: none;
  transition: none;
  width: var(--plone-toolbar-width-collapsed);
}
body.plone-toolbar-left #edit-zone .toolbar-label {
  display: none;
}
@media (min-width: 768px) {
  body.plone-toolbar-left.plone-toolbar-left-expanded {
    padding-left: var(--plone-toolbar-width);
  }
  body.plone-toolbar-left.plone-toolbar-left-expanded .toolbar-expand {
    display: none;
  }
  body.plone-toolbar-left.plone-toolbar-left-expanded .toolbar-collapse {
    display: block;
  }
  body.plone-toolbar-left.plone-toolbar-left-expanded #edit-zone {
    width: var(--plone-toolbar-width);
  }
  body.plone-toolbar-left.plone-toolbar-left-expanded #edit-zone .toolbar-label {
    display: inline-block;
    white-space: initial;
    hyphens: auto;
    word-break: break-word;
    vertical-align: top;
  }
}

/* extending dropdown from BS5 outside media query */
body.plone-toolbar-top {
  padding-left: var(--plone-toolbar-width-collapsed);
  padding-top: 0;
}
body.plone-toolbar-top .toolbar-expand,
body.plone-toolbar-top .toolbar-collapse {
  display: none;
}
body.plone-toolbar-top .toolbar-label {
  display: none;
}

@media (min-width: 768px) {
  body.plone-toolbar-top {
    padding-top: var(--plone-toolbar-top-height);
    padding-left: 0;
  }
  body.plone-toolbar-top .toolbar-collapse,
  body.plone-toolbar-top .toolbar-header {
    height: 100%;
  }
  body.plone-toolbar-top #edit-zone {
    bottom: unset;
    width: 100%;
    flex-direction: row;
    height: var(--plone-toolbar-top-height);
  }
  body.plone-toolbar-top #edit-zone .plone-toolbar-main {
    flex-direction: row;
  }
  body.plone-toolbar-top #edit-zone .plone-toolbar-main > .border-top {
    border-top: none;
    border-right: solid 1px var(--plone-toolbar-separator-color);
  }
  body.plone-toolbar-top #edit-zone .personaltools-wrapper {
    margin-left: auto;
    margin-top: 0;
    padding: 0;
    border-top: none;
  }
}
/* Dropdown fixes for mobile with top bar  */
@media (max-width: 767.98px) {
  body.plone-toolbar-top #edit-zone {
    width: var(--plone-toolbar-width-collapsed);
  }
  body.plone-toolbar-top #edit-zone .nav-link {
    padding-right: 0.5rem;
  }
  body.plone-toolbar-top #edit-zone .nav-link.dropdown-toggle::after {
    vertical-align: 0;
    margin-left: 0;
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  body.plone-toolbar-top #edit-zone .dropdown-menu.show {
    display: block;
    margin-left: var(--plone-toolbar-width-collapsed) !important;
    margin-top: -2.5rem !important;
  }
}








.template-folder_contents {

  .pat-structure {
    font-size: 16px;

    .navbar {
      --bs-navbar-padding-x: 0;
      --bs-navbar-padding-y: 0;
      --bs-navbar-color: rgba(0, 0, 0, 0.55);
      --bs-navbar-hover-color: rgba(0, 0, 0, 0.9);
      --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
      --bs-navbar-active-color: rgba(0, 0, 0, 0.7);
      --bs-navbar-brand-padding-y: 0.3125rem;
      --bs-navbar-brand-margin-end: 1rem;
      --bs-navbar-brand-font-size: 1.25rem;
      --bs-navbar-brand-color: rgba(0, 0, 0, 0.7);
      --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.7);
      --bs-navbar-nav-link-padding-x: 1rem;
      --bs-navbar-toggler-padding-y: 0.25rem;
      --bs-navbar-toggler-padding-x: 0.75rem;
      --bs-navbar-toggler-font-size: 1.25rem;
      --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
      --bs-navbar-toggler-border-radius: 0.375rem;
      --bs-navbar-toggler-focus-width: 0.25rem;
      --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
      position: sticky;
      top: 0;
      z-index: 10;
      border: none;
      min-height: auto;
      margin: 0 0 .5em;
      background-color: var(--bs-body-bg);
    }
    a {
      text-decoration: none;
    }

    .btn {
      --bs-btn-padding-x: 0.75rem;
      --bs-btn-padding-y: 0.375rem;
      --bs-btn-font-size: 1rem;
      --bs-btn-font-weight: 400;
      --bs-btn-line-height: 1.5;
      --bs-btn-color: #212529;
      --bs-btn-bg: transparent;
      --bs-btn-border-width: 1px;
      --bs-btn-border-color: transparent;
      --bs-btn-border-radius: 0.375rem;
      --bs-btn-hover-border-color: transparent;
      --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
      --bs-btn-disabled-opacity: 0.65;
      --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
      display: flex;
      align-items: center;
      padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
      font-family: var(--bs-btn-font-family);
      font-size: var(--bs-btn-font-size);
      font-weight: var(--bs-btn-font-weight);
      line-height: var(--bs-btn-line-height);
      color: var(--bs-btn-color);
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      border: var(--bs-btn-border-width) solid var(--bs-btn-border-color) !important;
      border-radius: var(--bs-btn-border-radius);
      background-color: var(--bs-btn-bg);
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      svg {
        display: block;
      }
    }
    .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
      color: var(--bs-btn-active-color);
      background-color: var(--bs-btn-active-bg);
      border-color: var(--bs-btn-active-border-color);
    }
    // .popover {
    //   position: absolute !important;
    // }
    .popover .popover-title {
      padding: 8px 14px;
      background-color: var(--bs-light);
    }
    .popover .popover-content {
      padding: 1rem 1rem;
    }
    .popover.attribute-columns ul {
      list-style: none;
      margin: 0 0 1ex 0;
      padding: 0;
    }
    .popover.attribute-columns ul li {
      list-style: none;
      cursor: move;
      border: 1px solid var(--bs-gray);
      border-bottom: none;
      padding: 3px 7px;
    }
    .popover.attribute-columns ul li label {
      margin-bottom: 0;
    }
    .popover.attribute-columns ul li label input[type="checkbox"] {
      margin-right: 1ex;
    }
    .popover {
      --bs-popover-zindex: 1070;
      --bs-popover-max-width: 600px;
      --bs-popover-font-size: 0.875rem;
      --bs-popover-bg: #fff;
      --bs-popover-border-width: 1px;
      --bs-popover-border-color: var(--bs-border-color-translucent);
      --bs-popover-border-radius: 0.5rem;
      --bs-popover-inner-border-radius: calc(0.5rem - 1px);
      --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      --bs-popover-header-padding-x: 1rem;
      --bs-popover-header-padding-y: 0.5rem;
      --bs-popover-header-font-size: 1rem;
      --bs-popover-header-bg: #f0f0f0;
      --bs-popover-body-padding-x: 1rem;
      --bs-popover-body-padding-y: 1rem;
      --bs-popover-body-color: #212529;
      --bs-popover-arrow-width: 1rem;
      --bs-popover-arrow-height: 0.5rem;
      --bs-popover-arrow-border: var(--bs-popover-border-color);
      z-index: var(--bs-popover-zindex);
      // display: block;
      max-width: var(--bs-popover-max-width);
      font-family: var(--bs-font-sans-serif);
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      text-align: start;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-break: normal;
      white-space: normal;
      word-spacing: normal;
      line-break: auto;
      font-size: var(--bs-popover-font-size);
      word-wrap: break-word;
      background-color: var(--bs-popover-bg);
      background-clip: padding-box;
      border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
      border-radius: var(--bs-popover-border-radius);
    }

    .btn-group {
      border-radius: .375rem;
    }
    .btn-group, .btn-group-vertical {
      position: relative;
      display: inline-flex;
      vertical-align: middle;
    }
    .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
    }
    .btn-outline-secondary {
      --bs-btn-color: #6c757d;
      --bs-btn-border-color: #6c757d;
      --bs-btn-hover-color: #fff;
      --bs-btn-hover-bg: #6c757d;
      --bs-btn-hover-border-color: #6c757d;
      --bs-btn-focus-shadow-rgb: 108,117,125;
      --bs-btn-active-color: #fff;
      --bs-btn-active-bg: #6c757d;
      --bs-btn-active-border-color: #6c757d;
      --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      --bs-btn-disabled-color: #6c757d;
      --bs-btn-disabled-bg: transparent;
      --bs-btn-disabled-border-color: #6c757d;
      --bs-gradient: none;
    }
  }
}