//*// TYPE //*//

//*// Body //*//
body { word-wrap: break-word; }

//*// Headings
h1, h2, h3, h4, h5, h6 {
  font-family: @plone-headings-font-family;
  font-weight: @plone-headings-font-weight;
  line-height: @plone-headings-line-height;
  color: @plone-headings-color;
}

h1, h2, h3 {
  // margin-top: @plone-line-height-computed;
  margin: 0 0 25px;
  a:hover, a:focus { text-decoration: none; border-bottom: 2px @plone-link-hover-color solid; }
}
h4, h5, h6 {
  // margin-top: (@plone-line-height-computed / 2);
  margin: 0 0 (@plone-line-height-computed / 2);
}

h1 {
  font-size: @plone-font-size-h1;
  color: @plone-primary-color;
  line-height: 2.500rem;
  font-weight: 700;
  letter-spacing: 3.7px;
  text-transform: uppercase;
  .article-header_news-item & {
    color: @plone-text-color;
    font-size: 3.571rem;
    letter-spacing: 0;
    line-height: 4.286rem;
    margin-bottom: 8px;
    text-transform: none;
  }
}
h2 {
  font-size: @plone-font-size-h2;
  color: @plone-primary-color;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  &.tileHeadline {
    font-size: 2.500rem;
    line-height: 2.500rem;
    text-transform: none;
    color: @plone-gray-darker;
    letter-spacing: 0;
    margin: 0 0 21px;
    a {
      color: @plone-gray-darker;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
h3 { font-size: @plone-font-size-h3; }
h4 { font-size: @plone-font-size-h4; }
h5 { font-size: @plone-font-size-h5; }
h6 { font-size: @plone-font-size-h6; }

//*// Body text
p {
  margin: 0 0 35px;
  &.large {
    font-size: 1.875rem;
    line-height: 2.625rem;
  }
}

.documentDescription {
  color: #717272;
  font-size: 1.688rem;
  font-weight: 400;
  line-height: 2.813rem;
  .article-header_news-item & {
    color: @plone-gray-dark; 
    font-size: 1.357rem;
    line-height: 1.750rem;
    font-weight: 300;
  }
}

//*// Emphasis & misc
small { font-size: 85%; }
cite { font-style: normal; }
mark { background-color: @plone-state-warning-bg; padding: .2em; }
.discreet { color: @plone-text-discreet; font-weight: @plone-font-weight-regular;}
.documentByLine {color: @plone-text-discreet; font-weight: @plone-font-weight-regular; margin-bottom: (@plone-line-height-computed / 2); }

// //*// Page header
// .documentFirstHeading {
//   padding-bottom: ((@plone-line-height-computed / 2) - 1);
//   margin: 0 0 (@plone-line-height-computed / 2);
//   border-bottom: 1px solid @plone-page-header-border-color;
// }

//*// Lists
ul,
ol {
  margin-top: 0;
  margin-bottom: 35px;
  ul,
  ol {
    margin-bottom: 0;
  }
}

dl {
  margin-top: 0; // Remove browser default
  margin-bottom: @plone-line-height-computed;
}
  dt, dd { line-height: @plone-line-height-base; }
  dt { font-weight: bold;}
  dd { margin-left: 0; }

//*// Misc
abbr[title], abbr[data-original-title] /*tooltip bootstrap plugin, just in case*/ {
  cursor: help;
  border-bottom: 1px dotted @plone-abbr-border-color;
}

//*// Addresses
address {
  margin-bottom: @plone-line-height-computed;
  font-style: normal;
  line-height: @plone-line-height-base;
}


//*// Floats
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

.text-body-color {
  color: @plone-text-color;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.no-bullet {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 5px;
    line-height: 1.625rem;
    @media (min-width: @plone-grid-float-breakpoint) {
      margin-bottom: 10px;
    }
  }
}
.list-header {
  text-transform: uppercase;
  font-weight: bold;
}
.image-inline {
  max-width: 100%;
  @media (max-width: @plone-screen-xs-max) {
    height: auto;
  }
}
.image-left {
  margin: 0 75px 95px 0;
  float: left;
  @media (max-width: @plone-screen-xs-max) {
    height: auto;
    max-width: 100%;
  }
  @media (max-width: @plone-screen-sm-max) {
    float: none;
    margin: 0 auto 35px;
  }
}
.image-right {
  margin: 0 0 95px 75px;
  float: right;
  @media (max-width: @plone-screen-xs-max) {
    height: auto;
    max-width: 100%;
  }
  @media (max-width: @plone-screen-sm-max) {
    margin: 0 auto 35px;
    float: none;
  }
}



.p-quote {
  font-family: Arial, Arial, Helvetica, sans-serif;
  font-size: 80px;
  line-height: 100px;
  margin: 0 0 6px;
}
i.glyphicon {
  display: none;
}