//*// TAGS //*//
.links-category {
  font-family: @plone-font-family-serif;
  font-size: 1.250rem;
  line-height: 1.750rem;
  font-weight: 300;
  color: @plone-gray-dark;
  margin-bottom: 23px;
  .article-header_news-item & {
    margin-bottom: 11px;
  }
}

.link-category {
  // font-family: @plone-font-family-serif;
  // font-size: 1.250rem;
  // line-height: 1.750rem;
  // font-weight: 300;
  // display: inline;
  // padding: .2em .6em .3em;
  // font-size: @plone-font-size-small;
  // font-weight: @plone-font-weight-regular;
  // line-height: 1;
  // color: @plone-btn-standalone-color;
  // text-align: center;
  // white-space: nowrap;
  // vertical-align: baseline;
  // border-radius: @plone-border-radius-base;
  // background-color: @plone-btn-standalone-bg;

  // &[href] {
  //   &:hover,
  //   &:focus {
  //     color: @plone-gray;
  //   }
  // }

  a& {
    color: @plone-gray-dark;
    // &:hover,
    // &:focus {
    //   // color: @plone-gray;
    //   // text-decoration: none;
    //   // cursor: pointer;
    // }
  }

  // Empty
  &:empty {
    display: none;
  }
}

// For tag categories linked to a content
#category {
  margin-bottom: @plone-padding-base-horizontal;
  ul {
  list-style: none;
  padding: 0;
  display: inline;
  }

  li {
    display: inline-block;
  }
}
