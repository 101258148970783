//*// FOOTER //*//
#portal-footer-wrapper {
  background-color: @plone-footer-bg;
  clear: both;
  color: @plone-gray-lighter;
  font-size: 1.250rem;
  padding: 80px 15px;
  @media (min-width: @plone-grid-large-breakpoint) {
    padding: 130px 15px;
  }
  .glyphicon {
    display: none;
  }
  .last-block {
    color: @plone-text-color;
  }
  a {
    color: #6a6a6a;
    &:hover {color: lighten(#6a6a6a,15%);}
  }
  .no-bullet {
    @media (max-width: @plone-screen-xs-max) {
      margin-bottom: 30px;
    }
  }
  .list-header {
    color: @plone-text-color;
    margin-bottom: 5px;
    @media (min-width: @plone-grid-float-breakpoint) {
      margin-bottom: 20px;
    }
    a {
      color: @plone-text-color;
    }
  }
}
#portal-footer-signature {
  color: @plone-gray;
}