//*// MAIN //*//
.content-header {
  background-color: #f8f8f8;
  background-image: url('../images/bg_header.png');
  background-size: cover;
  margin-bottom: 76px;
  padding-top: 71px;
}
.content-header-visual {
  background-color: fade(#f8f8f8, 90%);
  text-align: center;
  padding: 0 15px;
  .template-contact &,
  .portaltype-collection & {
    background-color: #fff;
  }
  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    min-height: 265px;
    padding: 30px 0;
    text-align: center;
    .portaltype-collection & {
      min-height: inherit;
    }
  }
}

.content-footer {
  margin-top: 80px;
  @media (min-width: @plone-grid-float-breakpoint) {
    display: flex;
  }
  .articleInfo {
    margin-right: 35px;
    padding-right: 35px;
    padding-top: 6px;
    @media (min-width: @plone-grid-float-breakpoint) {
      border-right: 1px solid @plone-gray-light; 
    }
  }
  .tailPortret {
    margin: 6px 0 5px;
  }
  .tileAuthor {
    clear: both;
    a {
      color: @plone-link-color;
    }
    img {
      margin-bottom: 3px;
    }
  }
  header {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.item-block {
  display: block;
}

// Blog

.tileItem {
  border-bottom: 2px solid @plone-gray;
  clear: both;
  margin: 0 0 42px;
  padding: 0 0 55px;
}
.tileImage {
  img {
    max-width: 100%;
    height: auto;
    width: auto;
  }
}

// FP
.bg-content-header {
  background-image: url('../images/bg_quote_0.png');
  background-size: cover;
  text-align: center;
}

//*// testimonial box
.testimonial {
  color: @plone-text-color;
  margin: 0 0 20px;
  @media (max-width: @plone-screen-xs-max) {
    padding: 0 15px;
  }
  &.large {
    font-size: 1.125rem;
    @media (min-width: @plone-grid-float-breakpoint) {
      font-size: 1.875rem;
    }
    p {
      font-size: 2.250rem;
      line-height: 2.500rem;
      margin: 16px 0;
      @media (min-width: @plone-grid-float-breakpoint) {
        font-size: 3.750rem;
        line-height: 4.688rem;
      }
      br {
        @media (max-width: @plone-screen-xs-max) {
          display: none;
        }
      }
    }
  }
  a {
    color: @plone-text-color;
    &:focus,
    &:hover {
      color: @plone-primary-color;
      text-decoration: none;
    }
  }
  p {
    font-family: @plone-font-family-condensed;
    &:after,
    &:before {
      display: inline-block;
      font-style: normal;
      font-family: Arial, Helvetica, sans-serif;
    }
    &:after {
      content: '”';
      margin-left: 10px;
    }
    &:before {
      content: '“';
      margin-right: 10px;
    }
  }
  .container-fluid {margin: 0 auto; width: 960px;}
  cite {
    &:before {
      content: "kkkkk";
      display: block;
      font-family: "arkusinc-icon";
      font-style: normal;
      font-size: 19px;
      margin: 0 16px 4px 22px;
      vertical-align: middle;
      @media (min-width: @plone-grid-float-breakpoint) {
        display: inline-block;
      }
    }
  }
  &.color-primary {
    a {
      color: @plone-primary-color;
      &:focus,
      &:hover {
        color: @plone-text-color;
        text-decoration: none;
      }
    }
  }
}
.testimonial-0 {
  align-content: stretch;
  color: #2f2f2f;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0;
  min-height: 366px;
  text-align: center;
  @media (min-width: @plone-grid-float-breakpoint) {
    letter-spacing: 1px;
  }
  &.large {
    font-size: 18px;
  }
  cite {
    font-weight: bold;
  }
  &.full-width {
    margin-bottom: 0;
  }
}
.testimonial-1 {
  align-content: stretch;
  background-image: url('../images/bg_quote_1.svg');
  background-size: cover;
  color: #2f2f2f;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 475px;
  justify-content: center;
  padding: 0 50px;
  text-align: center;
  width: 100%;
  @media (max-width: @plone-screen-xs-max) {
    background-position: 50% 50%;
    padding: 0 32px;
  }
  &.large {
    p {
      margin: 21px 0;
    }
  }
  cite {
    font-weight: bold;
  }
}
.testimonial-2 {
  p {
    font-size: 2.250rem;
    line-height: 2.875rem;
    @media (min-width: @plone-grid-float-breakpoint) {
      font-size: 2.500rem;
      line-height: 3.125rem;
    }
  }
  footer {
      font-size: 16px;
      line-height: 20px;
      text-align: right;
  }
  cite {
    &:before {
      content: "kkkkk";
      display: block;
      font-family: "arkusinc-icon";
      font-style: normal;
      margin: 0 7px 2px 5px;
      vertical-align: bottom;
      @media (min-width: @plone-grid-float-breakpoint) {
        display: inline-block;
      }
    }
  }
}


//*// Adjustments
// #content {margin-bottom: 140px;}

#content-core a {
  // border-bottom: 1px solid @plone-gray-lighter;

  &:hover,
  &:focus {
    border-bottom: none;
  }

  // &:focus {
  //   .tab-focus(); //--> NOT DONE
  // }
}
#content-core .pat-structure a, #content-core .pat-resourceregistry a {
  border-bottom: none;
}
#content-core .pat-structure a.btn, #content-core .pat-resourceregistry  a.btn, #content-core .pat-structure .pagination a {
  border-bottom: 1px solid @plone-gray-lighter;
}
a.plone-toolbar-logo {
	color: #fff;
}

//*// Containers
.container, .container-fluid {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

body:not(.section-front-page) {
  .content-footer .container,
  .content-header .container,
  .outer-wrapper > .container {
    @media (min-width: @plone-grid-large-breakpoint) {
      width: calc(~'100% - 480px');
    }
  }
}


// @media (min-width: @plone-screen-sm-min) { .container {width: @plone-container-sm;}}
// @media (min-width: @plone-screen-md-min) { .container {width: @plone-container-md;}}
// @media (min-width: @plone-screen-lg-min) { .container {width: @plone-container-lg;}}

@media (min-width: @plone-grid-large-breakpoint) {
  .container {
    max-width: 1920px;
    width: calc(~'100% - 174px');
  }
}



// @media (min-width: @plone-screen-md-min) { .container {width: @plone-container-md;}}
// @media (min-width: @plone-screen-lg-min) { .container {width: @plone-container-lg;}}

// @media (min-width: @plone-screen-sm-min) { .plone-toolbar-top-default .container {width: calc(~'100% - 176px');}}
// @media(min-width: (@plone-screen-sm-min + @plone-left-toolbar)) { .plone-toolbar-left-default .container {width: (@plone-container-sm);}}
// @media(min-width: (@plone-screen-sm-min + @plone-left-toolbar-expanded)) {.plone-toolbar-left-expanded .container {width: (@plone-container-sm);}}

// @media (min-width: @plone-screen-md-min) {.plone-toolbar-top-default .container {width: @plone-container-md;}}
// @media(min-width: (@plone-screen-md-min + @plone-left-toolbar)) {.plone-toolbar-left-default .container {width: (@plone-container-md);}}
// @media(min-width: (@plone-screen-md-min + @plone-left-toolbar-expanded)) {.plone-toolbar-left-expanded .container {width: (@plone-container-md);}}

// @media (min-width: @plone-screen-lg-min) {	.plone-toolbar-top-default .container {width: @plone-container-lg;}	}
// @media(min-width: (@plone-screen-lg-min + @plone-left-toolbar)) { .plone-toolbar-left-default .container {width: (@plone-container-lg);}}
// @media(min-width: (@plone-screen-lg-min + @plone-left-toolbar-expanded)) {.plone-toolbar-left-expanded .container {width: (@plone-container-lg);}}

 //#edit-zone {display: none}


.bg-1 {
  background-color: @plone-gray-lighter;
}
.border-1-style {
  background-color: #969a95;
  bottom: 21px;
  content: "";
  left: 0;
  position: absolute;
  top: 40px;
  width: 1px;
}
.border-1 {
  @media (min-width: 1366px) {
    &:not(:first-child):before {
      .border-1-style;
    }
  }
  @media (min-width: @plone-screen-sm-min) and (max-width: 1366px) {
    &:nth-child(3n+2):before {
      .border-1-style;
    }
  }
  @media (max-width: @plone-screen-xs-max) {
    border-bottom: 1px solid #969a95;
  }
}
.border-2 {
  @media (min-width: 1366px) {
    &:not(:first-child):before {
      .border-1-style;
      top: 21px;
    }
  }
  @media (min-width: @plone-screen-sm-min) and (max-width: 1366px) {
    &:nth-child(3n+2):before {
      .border-1-style;
      top: 21px;
    }
  }
  @media (max-width: @plone-screen-xs-max) {
    border-bottom: 1px solid #969a95;
  }
}



.full-width {
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: @plone-grid-large-breakpoint) {
    margin: 0 -255px 20px;
    .section-front-page & {
      margin-left: calc(~'-204px / 2');
      margin-right: calc(~'-204px / 2');
    }
  }
}

.block-align {
  text-align: right;
  > p {
    @media (max-width: @plone-screen-xs-max) {
      text-align: center;
    }
  }
}


.box-visual {
  @media (min-width: @plone-screen-md-min) {
    padding: 43px 0;
  }
}
.box-item {
  display: block;
  padding: 20px;
  position: relative;
  .advantages & {
    @media (min-width: @plone-grid-float-breakpoint) {
      width: 33%;
    }
  }
}
a.box-item {
  border-radius: 12px;
  text-decoration: none;
  transition-duration: 0.2s;
  &:hover {
    background-color: lighten(@plone-primary-color, 45%);
    text-decoration: none;
  }
}
.box-title {
  display: block;
}
.box-description {
  display: block;
}
// .box-icon {
//   font-family: "arkusinc-icon";
//   font-size: 220px;
//   line-height: 1;
//   .advantages & {
//     margin-bottom: 75px;
//     min-height: 330px;
//     align-items: flex-end;
//     display: flex;
//     justify-content: center;
//     align-content: center;
//   }
//   .advantages .box-item:nth-child(2) & {
//     font-size: 310px;
//   }
// }

// ***
// * Styles Boxs Group 1
// ***
.box-style-1 {
  .box-item {
    @media (min-width: @plone-grid-float-breakpoint) {
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 0;
    }

    .box-icon img {
      @media (max-width: 1365px) {
        width: 148px;
      }
      @media (max-width: 967px) {
        width: 100px;
      }
      @media (max-width: 767px) {
        width: auto;
      }
    }
    &:nth-child(2) {
      .box-icon img {
        @media (max-width: 1365px) {
          width: 265px;
        }
        @media (max-width: 967px) {
          width: 175px;
        }
        @media (max-width: 767px) {
          width: auto;
        }
      }
    }
  }
  .box-visual {
    font-family: @plone-font-family-sans-serif;
    @media (min-width: @plone-grid-float-breakpoint) {
      flex-wrap: wrap;
      align-content: center;
      align-items: stretch;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    @media (min-width: 1366px) {
      flex-wrap: nowrap;
    }
  }
  .box-icon {
    align-content: center;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-bottom: 82px;
    min-height: 320px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .box-title {
    color: #343433;
    font-family: @plone-font-family-condensed;
    font-size: 5.000rem;
    line-height: 5.000rem;
    margin-bottom: 21px;
  }
  .box-description {
    color: #89ad49;
    font-family: @plone-font-family-sans-serif;
    font-size: 2.250rem;
    font-weight: bold;
    line-height: 2.750rem;
    margin-bottom: 28px;
    .advantages& {
      text-transform: uppercase;
      font-size: 2.500rem;
      @media (min-width: @plone-grid-large-breakpoint) {
        font-size: 3.750rem;
      }
    }
  }
}

// ***
// * Styles Boxs Group 2
// ***
.box-style-2 {
  .box-item {
    width: 100%;
    @media (min-width: @plone-screen-md-min) {
      width: 31%;
      &:not(:first-child) {
        margin-left: 2%;
      }
    }
    @media (min-width: @plone-grid-large-breakpoint) {
      width: 30.5%;
      &:not(:first-child) {
        margin-left: 3%;
      }
    }
  }
}



.box-green-round {
  background-color: #89ad49;
  border-radius: 70px;
  display: inline-block;
  padding: 0 0 26px 20px;
  margin-bottom: 35px;
  width: 100%;
  .boxs-group-12 & {
    @media (min-width: @plone-screen-md-min) {
      margin-top: 20px;
    }
  }
  .box-title {
    color: @plone-primary-color;
    font-size: 1.875rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 30px 5% 40px;
    line-height: 2.625rem;
    text-align: center;
    @media (min-width: @plone-grid-float-breakpoint) {
      padding: 42px 40px 54px;
    }
    @media (min-width: @plone-grid-large-breakpoint) {
      padding: 42px 62px 54px;
    }
    .boxs-group-7 & {
      font-size: 42px;
      padding: 30px 5% 7px;
      line-height: 50px;
      @media (min-width: @plone-grid-float-breakpoint) {
        padding: 57px 62px 7px;
      }
    }
  }
  .box-description {
    &:before {
      content: "";
      width: 80%;
      margin: 0 auto 37px;
      height: 2px;
      display: block;
      background-color: #94979a;
    }
    font-size: 1.563rem;
    padding: 17px 5% 40px;
    line-height: 35px;
    @media (min-width: @plone-grid-large-breakpoint) {
      padding: 17px 64px 61px;
    }
    @media (min-width: @plone-grid-float-breakpoint) {
      padding: 17px 40px 61px;
    }
  }
  .box-item-visual {
    background-color: #fff;
    border-radius: 35px;
    border: 14px solid #94979a;
  }
}


.box-style-3 {
  .box-visual {
    @media (min-width: @plone-grid-float-breakpoint) {
      align-content: center;
      align-items: stretch;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding-bottom: 10px;
    }
    @media (min-width: 1366px) {
      flex-wrap: nowrap;
    }
  }
  .box-icon {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    min-height: 330px;
  }
  .box-item {
    @media (min-width: @plone-grid-float-breakpoint) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      width: 20%;
    }
  }
  .box-title {
    color: @plone-primary-color;
    font-size: 1.625rem;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 1.875rem;
    margin-bottom: 25px;
    text-transform: uppercase;
    @media (min-width: @plone-grid-large-breakpoint) {
      font-size: 1.875rem;
      line-height: 2.688rem;
    }
  }
  .box-description {
    color: @plone-gray-dark;
    font-size: 1.500rem;
    line-height: 2.188rem;
    margin-bottom: 28px;
  }
}
.box-style-4 {
  .box-style-3;
  .boxs-group-5 & .box-visual {
    margin-bottom: 90px;
  }
  .box-icon {
    min-height: auto;
  }
}



.boxs-group {
  color: #717272;
  &.boxs-group-9 {
    font-size: 26px;
    line-height: 35px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: @plone-grid-float-breakpoint) {
      width: 87%;
    }
  }
  &.boxs-group-10 {
    margin-left: auto;
    margin-right: auto;
    @media (min-width: @plone-grid-float-breakpoint) {
      width: 81%;
    }
  }
  &.boxs-group-12 {
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;
  }
  h2 {
    margin: 0 0 15px;
    @media (min-width: @plone-grid-float-breakpoint) {
      margin: 0 0 52px;
    }
  }
  .section-about &:not(.full-width) {
    @media (min-width: @plone-screen-sm-min) and (max-width: 1599px){
      margin-left: auto;
      margin-right: auto;
      width: 88%;
    }
  }
}
.boxs-visual {
  clear: both;
  padding: 80px 0 14px;
  @media (min-width: @plone-grid-large-breakpoint) {
    padding: 164px 0 14px;
  }
}


.boxs-group-3 {
  padding: 34px 0;
  p {
    margin: 0 0 26px;
    .image-inline {
      margin-bottom: 63px;
    }
  }
}

.boxs-group-5 {
  p {
    @media (min-width: @plone-screen-md-min) {
      margin-bottom: 84px;
    }
  }
}
.boxs-group-7 {
  .boxs-visual {
    @media (min-width: @plone-screen-md-min) {
      padding: 164px 0 14px;
    }
  }
  .box-visual {
    @media (min-width: @plone-screen-md-min) {
      padding: 92px 0;
    }
  }
}
.boxs-group-10 {
  blockquote {
    font-size: 2.500rem;
    font-weight: bold;
    line-height: 3.125rem;
    margin: 35px auto;
    text-align: center;
    @media (min-width: @plone-grid-float-breakpoint) {
      margin: 83px auto 58px;
      width: 82%;
    }
  }
}


.boxs-last-group {
  align-content: center;
  align-items: center;
  background-image: url('../images/bg_last_block.png');
  background-size: cover;
  color: @plone-text-color;
  display: flex;
  font-family: @plone-font-family-serif;
  font-size: 2.500rem;
  // font-weight: bold;
  justify-content: center;
  line-height: 3.125rem;
  margin-bottom: 0;
  min-height: 315px;
  text-align: center;
  padding: 35px 0;
  @media (min-width: @plone-screen-md-min) {
    margin-top: 135px;
    margin-bottom: 0;
  }
}

// About page
.boxs-group-about.boxs-group-0 {
  margin-top: -76px;
}

.color-primary {
  color: @plone-primary-color;
  &.testimonial-2 footer {
    color: @plone-text-color;
  }
}
.flex-container {
  @media (min-width: @plone-grid-float-breakpoint) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
}


.border-style-2 {
  @media (min-width: @plone-grid-float-breakpoint) {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: -25px;
      bottom: 0;
      right: -10px;
      width: 1px;
      background-color: #ccc;
    }
  }
}


.block-testimonial-footer {
  text-align: center;
  @media (min-width: 360px) {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
}
.block-testimonial-footer_logo {
  margin-right: 5px;
  margin-bottom: 10px;
  @media (min-width: 360px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.block-testemonial-footer_title {
  @media (min-width: 360px) {
    margin-bottom: 0;
  }
}

// list
.list-style-minus {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
  li {
    margin-bottom: 6px;
    &:before {
      content: "-";
      margin-right: 6px;
    }
  }
}

// .about-management block
.about-management {
  margin-bottom: 45px;
  text-align: center;
}
.about-management_title {
  color: @plone-text-color;
  font-weight: bold;
  padding: 15px 20px;
  @media (min-width: @plone-grid-float-breakpoint) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100px;
  }
}
.about-management_description {
  &:before {
    border-top: 1px solid #ccc;
    content: "";
    display: block;
    margin-bottom: 15px;
  }
}

// our-certifications block
.our-certifications-list-1 {
  @media (min-width: @plone-grid-float-breakpoint) {
    padding-left: 30px;
    text-align: right;
  }
}

.list {
  list-style: none;
  @media (max-width: @plone-screen-xs-max) {
    padding-left: 0;
  }
}
.list-cloud-computing-expertise {
  font-family: @plone-font-family-condensed;
  color: @plone-text-color;
}
.list-cloud-computing-expertise_item {
  margin-bottom: 15px;
}
.list-cloud-computing-expertise_item_icon {
  margin-right: 15px;
  @media (min-width: @plone-grid-float-breakpoint) {
    margin-right: 30px;
  }
}
.list-cloud-computing-expertise_item_title {
  font-size: 26px;
  @media (min-width: @plone-grid-float-breakpoint) {
    font-size: 34px;
  }
}

// News item
.article-header_news-item {
  margin-bottom: 35px;
  @media (min-width: @plone-grid-float-breakpoint) {
    display: flex;
  }
  #section-leadimage,
  .leadImage {
    margin-right: 38px;
    max-width: 200px;
  }
}

// Addthis
.addthis_inline_share_toolbox {
  .at-style-responsive .at-share-btn {
    height: 34px;
  }
  .at-resp-share-element .at-share-btn .at-icon-wrapper {
    width: 22px !important;
    height: 22px !important;
    svg {
      max-width: 100%;
      height: auto !important;
    }
  }
  .at-label {
    font-size: 14px !important;
    font-weight: bold;
    line-height: 27px !important;
    height: auto !important;
  }
}