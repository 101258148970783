//*// VIEWS //*//
//standard view
//table view inherited form tables & tumbnail view on thumbs.plone.less
.folder-listing {
	font-weight: @plone-font-weight-bold;
	list-style: none;
	position: relative;
	padding-left: 20px;
	//need to add a list portlets-like bullet
	li {margin: 10px 0;}
	> li::before {
			content:"•"; position: absolute; font-size: @plone-font-size-large+1; margin-top: -9px; left: 0;
			color: lighten(@plone-portlet-list-bullet, 15%);
	}
	.documentByLine {font-weight: @plone-font-weight-regular; color: @plone-gray-light;}
}

//summary view
.tileItem {
  > .row {
    @media (min-width: @plone-screen-md-min) {
      padding-right: 50px;
    }
    @media (max-width: @plone-screen-xs-max) {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      .col-sm-3 {
        align-self: auto;
        flex: 0 1 auto;
        order: 2;
      }
      .col-sm-7 {
        align-self: auto;
        flex: 0 1 auto;
        order: 2;
      }
      .col-sm-2 {
        align-self: auto;
        flex: 0 1 auto;
        order: 1;
      }
    }
  }
}
.tileImage {
  margin-top: 4px;
  @media (max-width: @plone-screen-xs-max) {
    margin-bottom: 23px;
  }
}
.tileBody {
  font-size: 1.500rem;
  line-height: 2.000rem;
  margin-bottom: 43px;
}
.tileFooter {
  font-family: @plone-font-family-serif;
  font-size: 1.250rem;
  font-weight: 400;
  line-height: 2.000rem;
  text-transform: uppercase;
}
.tailPortret {
  margin: 0 12px 5px 0;
  @media (min-width: @plone-grid-float-breakpoint) {
    margin: 14px 0 5px;
  }
  @media (max-width: @plone-screen-xs-max) {
    float: left;
  }
}
.tileAuthor {
  @media (max-width: @plone-screen-xs-max) {
    text-align: left;
  }
  font-size: 1.250rem;
  line-height: 1.563rem;
  .article-header_news-item & {
    font-size: 1.357rem;
    margin-bottom: 15px;
  }
  a {
    color: @plone-text-color;
    .article-header_news-item & {
      color: @plone-link-color;
    }
  }
}
.tileDate {
  color: @plone-gray-dark;
  font-family: @plone-font-family-serif;
  font-size: 1.250rem;
  font-weight: 300;
  line-height: 1.500rem;
  @media (max-width: @plone-screen-xs-max) {
    text-align: left;
  }
}

.authorInfo {
  clear: both;
}

.articleInfo {
  .clearfix();
  margin-bottom: 35px;
}

// .tileImageNoFloat { margin-bottom: 5px; }
// clear floats:
.entries::before,
#content article.entry::after,
#searchresults ul li::after  {
  content: " ";
  display: block;
  clear: both;
  height:2px;  //allow minimal vertical space for thumbs
  visibility: hidden;
}
//structure pattern, fix thumb positioning in foldercontents
.icon-group-right .image-icon {
  float:none;
}


// Related Item Box
#relatedItemBox {
  font-family: @plone-font-family-serif;
  // font-size: 1.250rem;
  // line-height: 1.750rem;
  margin-bottom: 40px;
  header {
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    .image-icon {
      margin-right: 10px;
    }
    a {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: flex-end;
      align-items: flex-start;
    }
  }
  li {
    margin-bottom: 10px;
  }
}

#categories-filed-under {
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  color: @plone-text-color;
}
#category {
  font-family: @plone-font-family-serif;
  a {
    color: @plone-primary-color;
  }
}
