// //*// FONTS //*//
// *****
// * ARKUSINC ICON
// *****
.font-face(
  @font-path: '../fonts/';
  @file-name: 'arkusinc-icon';
  @font-family: 'arkusinc-icon';
  @font-weight: normal;
  @font-style:  normal;
  @svg-id: 'arkusinc-icon';
);

// *****
// * DIN
// *****
.font-face(
  @font-path: '../fonts/';
  @file-name: 'din_black-webfont';
  @font-family: 'Din Black';
  @font-weight: normal;
  @font-style:  normal;
  @svg-id: 'dinblack';
);
.font-face(
  @font-path: '../fonts/';
  @file-name: 'din_medium-webfont';
  @font-family: 'Din';
  @font-weight: 400;
  @font-style: normal;
  @svg-id: 'dinmedium';
  @local: ~"local('Din Regular'),";
);
.font-face(
  @font-path: '../fonts/';
  @file-name: 'din_light-webfont';
  @font-family: 'Din';
  @font-weight: 300;
  @font-style: normal;
  @svg-id: 'dinlight';
  @local: ~"local('Din Light'),";
);

// *****
// * Myriad Pro
// *****
// 400 Regular
@font-face {
  font-family: "Myriad Pro";
  src: url('../fonts/MyriadProRegular.eot');
  src: local('MyriadPro Regular'), url('../fonts/MyriadProRegular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MyriadProRegular.woff') format('woff'),
       url('../fonts/MyriadProRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
// 400 Italic
@font-face {
  font-family: "Myriad Pro";
  src: url('../fonts/MyriadProItalic.eot');
  src: local('MyriadPro Italic'), url('../fonts/MyriadProItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MyriadProItalic.woff') format('woff'),
       url('../fonts/MyriadProItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
// 500 SemiBold
@font-face {
  font-family: "Myriad Pro";
  src: url('../fonts/MyriadProSemiBold.eot');
  src: local('MyriadPro Semi Bold'), url('../fonts/MyriadProSemiBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MyriadProSemiBold.woff') format('woff'),
       url('../fonts/MyriadProSemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
// 700 Bold
@font-face {
  font-family: "Myriad Pro";
  src: url('../fonts/MyriadProBold.eot');
  src: local('MyriadPro Bold'), url('../fonts/MyriadProBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MyriadProBold.woff') format('woff'),
       url('../fonts/MyriadProBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
