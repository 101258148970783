//*// BOOTSTRAP GRID //*//

//*// This is exactly BOOTSTRAP GRID, replace it for your system



// Container widths
// .container {
//   .container-fixed();

//   @media (min-width: @plone-screen-sm-min) {
//     width: @plone-container-sm;
//   }
//   @media (min-width: @plone-screen-md-min) {
//     width: @plone-container-md;
//   }
//   @media (min-width: @plone-screen-lg-min) {
//     width: @plone-container-lg;
//   }
// }


// Fluid container
.container-fluid {
  .container-fixed();
}


// Row
.row {
  .clearfix();
  .make-row();
}


// Columns
.make-grid-columns();

.w-100 {
  width: 100%;
  @media (min-width: 1366px) {
    width: auto;
  }
}

@media (min-width: @plone-grid-large-breakpoint) {
  #main-container {
    &.row {
      margin-left: -40px;
      margin-right: -40px;
    }
    > .col-xs-1,
    > .col-xs-2,
    > .col-xs-3,
    > .col-xs-4,
    > .col-xs-5,
    > .col-xs-6,
    > .col-xs-7,
    > .col-xs-8,
    > .col-xs-9,
    > .col-xs-10,
    > .col-xs-11,
    > .col-xs-12 {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .content-footer .container,
  .content-footer .col-sm-9,
  .content-footer .col-sm-12,
  #content {
    > .row {
      margin-left: -40px;
      margin-right: -40px;
      > .col-sm-1,
      > .col-sm-2,
      > .col-sm-3,
      > .col-sm-4,
      > .col-sm-5,
      > .col-sm-6,
      > .col-sm-7,
      > .col-sm-8,
      > .col-sm-9,
      > .col-sm-10,
      > .col-sm-11,
      > .col-sm-12 {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}
// Extra small grid
.make-grid(xs);


// Small grid
@media (min-width: @plone-screen-sm-min) {
  .make-grid(sm);
}


// Medium grid
@media (min-width: @plone-screen-md-min) {
  .make-grid(md);
}


// Large grid
@media (min-width: @plone-screen-lg-min) {
  .make-grid(lg);
}


// toolbar needs to match grid
#edit-zone.plone-toolbar-top .plone-toolbar-container {
  .container-fixed();
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md;
  }
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg;
  }
  margin-top: 0;
  margin-bottom: 0;
  height: 50px;
}
#edit-zone.plone-toolbar-top.compressed .plone-toolbar-container {width: auto;}