//*// IMAGE //*//

.image-product {
	margin: 0;
	overflow: hidden;
	display: inline-block;
	figcaption {
		font-size: @plone-font-size-small;
		background: @plone-portlet-footer-bg;
		margin: @plone-padding-base-vertical @plone-padding-base-horizontal;
	}
}


// User photo inherit some classes
.portraitPhoto {
	.image-product();
	.pull-right();
  margin: 0 0 1rem 1rem;
}
